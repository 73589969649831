@import '../../styles/variables.scss';

.btn {
  background-color: $primary-color;
  color: rgb(255, 255, 255);
  font-size: 15px;
  cursor: pointer;
  transition-duration: 0.3s;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  border-radius: 6px;
  font-weight: bold;
  position: relative;
  padding: 8px 18px;
  border: 1px solid darken($primary-color, 5);
  &:disabled {
    opacity: 0.8;
    cursor: default;
  }
  &:hover:not(:disabled) {
    background-color: darken($primary-color, 5);
  }
  &:focus {
    outline: none;
  }
}

.btnLoading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.transparent {
  background-color: transparent;
  color: $primary-color;
  border: 1px solid $primary-color;
  &:hover {
    background-color: rgba($primary-color, 0.1) !important;
  }
}

.danger {
  color: white;
  background-color: #e74141;
  border: 1px solid #e74141;
  &:hover {
    background-color: darken(#e74141, 10%) !important;
  }
}

.secondary {
  background-color: $secondary-color;
  color: white;
  border: 1px solid $gray-color;
  &:hover {
    background-color: darken($secondary-color, 5) !important;
  }
}